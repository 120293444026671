import React, { useState } from 'react';
import './Register.scss';
import TextField from '@material-ui/core/TextField';
import PasswordElement from '../../elements/form/password/PasswordElement';
import Button from '@material-ui/core/Button';
import TitleElement from '../../elements/title/TitleElement';
import { navigate } from 'gatsby';
import { UserConsumer } from '../../contexts/UserContext';
import GoogleLogin from '../../elements/login/google/GoogleLogin';

function Register() {
  let thisUser = {};
  
  const [elements, setElements] = useState({
    name: '',
    lastname: '',
    email: '',
    password: '',
  });

  function singIn() {
    if (window) {
      window.pUserRegisterData = {
        name: elements.name,
        lastname: elements.lastname
      }
    }
    thisUser.custom.createUserWithRegister(elements);

    setElements({
      name: elements.name,
      lastname: elements.lastname,
      email: elements.email,
      password: elements.password,
    });
  }

  function onChange(e) {
    var obj = [];
    obj[e.target.name] = e.target.value;
    setElements({...elements, ...obj });
  }

  return <div className="block login" >
    <UserConsumer>
      {
        user => {
          thisUser = user;
          if (user.isLogin) {
            navigate('/');
          }
          else {
            return <>
              <form>
                <TitleElement type="h1" >Registrate</TitleElement>
                <TextField name="name" label="Nombres" value={elements.name} onChange={onChange} /><br/>
                <TextField name="lastname" label="Apellidos" value={elements.lastname} onChange={onChange} /><br/>
                <TextField label="Correo Electrónico" name="email" value={elements.email} onChange={onChange} /><br/>
                <PasswordElement name="password" value={elements.password} onChange={onChange} />
                <Button onClick={singIn} variant="contained" className="send" variant="contained" color="primary">
                  Enviar
                </Button>
              </form>
              <div className="p-login-container" ><GoogleLogin></GoogleLogin></div>
            </>;
          }
        }
      }
    </UserConsumer>
  </div>;
}

export default Register;