import React, { Component } from 'react';
import CommunityHeader from '../../blocks/community/CommunityHeader';
import VideoYoutube from '../../blocks/video-youtube/VideoYoutube';
import Layout from '../../layout';
import { UserConsumer } from '../../contexts/UserContext';
import './EventPage.scss';
import firebase from "gatsby-plugin-firebase";


class EventPage extends Component {
  constructor () {
    super();
    this.state = {
      youtube: '',
      iframe: '',
    };
  }

  componentDidMount () {
    let this__ = this;
    var starCountRef = firebase.database().ref('comunidad-drupal/evento/cache-drupal/iframe'); // /evento/cache-drupal
    starCountRef.on('value', function(snapshot) {
      this__.setState({
        ...this__.state, iframe: snapshot.val()
      });
    });
    var starCountRef = firebase.database().ref('comunidad-drupal/evento/cache-drupal/youtube'); // /evento/cache-drupal
    starCountRef.on('value', function(snapshot) {
      this__.setState({
        ...this__.state, youtube: snapshot.val()
      });
    });
  }

  componentWillUnmount () {

  }

  removeElement () {

  }

  render() {
    //new Muuri('.grid');
    
    return <Layout>
      <div className="p page event">
        <CommunityHeader data={{
          title: 'Evento Drupal',
          subtitle: 'Manejo de caches',
          image: {
            id: '4cef7675-7c04-4cfb-8569-8f430a8410f9',
            alt: 'Drupal',
          }
        }}></CommunityHeader>
        <VideoYoutube id={this.state.youtube} iframe={this.state.iframe} ></VideoYoutube>
      </div>
    </Layout>
  }
}

export default EventPage;