import React, { Component } from 'react';
import './LoginRegisterModal.scss';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Login from '../login/Login';
import Register from '../register/Register';
import ForgetPassword from '../forget-password/ForgetPassword';
import { UserConsumer } from '../../contexts/UserContext';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5]
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const LoginRegisterModal = function() {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  let thisUser = {};

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return <UserConsumer>
        {
          user => {
            thisUser = user;
            if (user.isLogin) {
              //console.log('user', user);
              return <></>;
            }
            else {
              return <div className="block p-block-modal-login-register" >
                <div className="p-modal-login-register-button" onClick={handleOpen}>
                  <span className="p-centralmessage" >¡Click para entrar!</span>
                </div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  <Fade in={open}>
                    <div style={modalStyle} className={classes.paper}>
                      <AppBar position="static">
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                          <Tab label="Iniciar sesión" {...a11yProps(0)} />
                          <Tab label="Registrarte" {...a11yProps(1)} />
                          <Tab label="Olvide mi contraseña" {...a11yProps(2)} />
                        </Tabs>
                      </AppBar>
                      <TabPanel value={value} index={0}>
                        <Login></Login>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <Register></Register>
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <ForgetPassword></ForgetPassword>
                      </TabPanel>
                    </div>
                  </Fade>
                </Modal>
              </div>;
            }
          }
        }
      </UserConsumer>;
}

export default LoginRegisterModal;