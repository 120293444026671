import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import './VideoYoutube.scss';
import LoginRegisterModal from '../login-register-modal/LoginRegisterModal';
import Chat from '../chat/Chat';


class VideoYoutube extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
  }

  render () {
    
    var idYoutube = this.props.id;
    var domain = this.props.domain;
    var iframe = (this.props.iframe ? this.props.iframe : '');
    let hasIframe = true;
    if (iframe === '') {
      hasIframe = false;
    }

    return (
      <div className="block video-youtube" >
        <div className={'container-video-youtube ' + (hasIframe ? 'with-iframe' : '')} >
          <div className="section event" >
            <Grid container spacing={0}>
              <Grid item xs={12} sm={9} className={'cont-iframe'}>
                <div className="container-iframe" >
                  <iframe src={iframe} ></iframe>
                  <LoginRegisterModal/>
                </div>
              </Grid>
              <Grid item xs={12} sm={(iframe ? 3 : 9)} className={'cont-video'}>
                <div className="container-video" >
                  <iframe className="video"
                    allowfullscreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen" 
                    msallowfullscreen="msallowfullscreen" 
                    oallowfullscreen="oallowfullscreen" 
                    webkitallowfullscreen="webkitallowfullscreen" width="560" height="315" src={ 'https://www.youtube.com/embed/' + idYoutube + '?autoplay=1'} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                </div>
                <div className="container-chat" >
                  <Chat info={{ firestore: 'messages--comunidad-drupal' }} ></Chat>
                  {/*
                    <iframe className="chat" width="150px" height="100px" src={ 'https://www.youtube.com/live_chat?v=' + idYoutube + '&amp;embed_domain=' + domain } ></iframe>
                  */}
                </div>
              </Grid>
              <Grid item xs={12} sm={3} className={'cont-chat'} >
                <Chat info={{ firestore: 'messages--comunidad-drupal' }} ></Chat>
                {/*
                  <iframe className="chat"src={ 'https://www.youtube.com/live_chat?v=' + idYoutube + '&amp;embed_domain=' + domain } ></iframe>
                  <LoginRegisterModal/>
                */}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoYoutube;